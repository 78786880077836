import LocalizedStrings from 'react-localization';

export default  new LocalizedStrings({
    en: {
        usersTable:'USERS',
        firstName:'First name',
        lastName:'Last name',
        email:'Email',
        phone:'Phone',
        block:'Block',
        action:'Action',
        yes:'yes',
        no:'no',
        areYouSureDelete:'Are you sure to delete ?',
        addNewRecored:'ADD NEW RECORD',
        first:'FIRST',
        last:'LAST',
        next:'NEXT',
        prev:'PREV',
        login:"Login",
        signin:'SIGN IN',
        password:'Password',
        userDataIncorrect:'User data in correct',
        thisUserIsnotAdmin:'This user is not admin',
        active:'Active',
        type:'Type',
        signUpFrom:'Signup from',
        address:'Address',
        updateProfile:'Update Profile',
        area:'Area',
        activeButton:'ACTIVE',
        disActiveButton:'DIS-ACTIVE',
        blockButton:'BLOCK',
        unblockButton:'UN-BLOCK',
        remove:'REMOVE',
        productsTable:'PRODUCTS',
        name:'Name',
        compound:'Compound',
        price:'Price',
        quantity:'Quantity',
        hasOffer:'Has offer',
        offerPrice:'Offer price',
        offerRatio:'Offer ratio',
        top:'Top',
        category:'Category',
        sallCount:'sallCount',
        userProfile:'User Info',
        addOffer:'ADD OFFER',
        removeOffer:'REMOVE OFFER',
        disActive:'Dis-Active',
        normal:'NORMAL',
        categoriesTable:'CATEGORIES',
        arabicName:'Arabic name',
        englishName:'English name',
        categoryInfo:'Category Info',
        updateCategory:'Update Category',
        cities:'Cities',
        citiesTable:'CITIES',
        delivaryCost:'Delivary cost',
        cityInfo:'City Info',
        updateCity:'Update city',
        areaInfo:'Area Info',
        updateArea:'Update area',
        username:'UserName',
        forgetPassword:'Forget Password?',
        sendCode:'Send Code',
        enter:'Enter',
        code:'Code',
        newPassword:'New Password',
        newTime:'Repeate Password',
        enterCorrectPhone:'UnCorrect Phone',
        enterCorrectEmail:'UnCorrect Email',
        correctCode:'UnCorrect Code',
        passwordMustEqualConfirmPassword:'Password Not Equal Confirm Password',
        done:'Operation Done Successfully',
        addCategory:'Add Category',
        areYouSure:"Are You Sure?",
        ok:'Ok',
        cancel:'Cancel',
        noData:'No Data',
        completeData:'Complete Require Data',
        arabicCategoryName:'Arabic Category Name',
        englishCategoryName:'English Category Name',
        categoryImg:'Category Img',
        chooseFile:'Choose File ',
        dropHere:'Drop File Here',
        add:'Add',
        edit:'Edit',
        addProduct:'Add Product',
        idFilter:' Filter (ID)',
        nameFilter:' Filter (Name)',
        cardFilter:'Filter (Card)',
        salesManFilter:'Filter (salesMan)',
        salesMan:'SalesMan',
        all:'All',
        print:'Print',
        mainImg:'Main Image',
        arabicProductName:'Arabic Product Name',
        englishProductName:'English Product Name',
        writeArabicName:'Write Arabic Name',
        writeEnglishName:'Write English Name',
        EnterQuantity:'Enter Quantity',
        purchasePrice:'Purchase Price',
        EnterPrice:'Enter Price',
        commission:'Commission',
        imgsGroup:'Imgs Group',
        cashPrice:'Cash Price',
        Properties:'Properties',
        installmentPackages:'Installment Packages',
        firstPaid:'First Paid',
        months:'Months',
        installment:'Installment',
        theInstallment:'Installment',
        pound:'Pound',
        available:'Available',
        onlyInstallment:'Only Installment',
        ratio:'Ratio',
        property:'Property',
        description:'Description',
        theMonths:'Months',
        addAds:'Add Ads',
        day:'Day ',
        show:'Visable',
        notShow:'Not Visable',
        product:'Product',
        imgOrVideo:'img / video',
        enterName:'Enter Name',
        enterDuration:'Enter Duration',
        chooseCategory:'Choose Category ',
        enterPhone:'Enter Phone ',
        enterLink:'Enter Link (Optional)',
        link:'Link',
        chooseCompounds:'Choose Compounds (Optional)',
        chooseProducts:'Choose Products (Optional)',
        optional:'Optional',
        startDate:'Start Date',
        compounds:'Compounds',
        categories:'Categories',
        products:'Products',
        chooseDate:'Choose Date',
        visable:'Visable',
        addClient:'Add Client',
        chooseCity:'Choose City',
        city:'City',
        note:'Note',
        anotherPhone:'Another Phone',
        EnterPhone:'Enter Phone',
        EnterAnotherPhone:'Enter Another Phone',
        enterNote:'Enter Notes',
        enterAddress:'Enter Address',
        enterCardNum:'Enter Card Num',
        cardNum:'Card Num',
        profileImg:'Profile img',
        receipts:'Reciepts Imgs',
        cardImg:'Card Img',
        client:'Client',
        guarantor:'Guarantor',
        enterPassword:'Enter Password',
        delayPremuim:'Delay Premuim',
        payCash:'Pay Cash',
        collectPremuims:'Collect Premuims',
        printReceitp:'Print Receitp',
        sendNotif:'Send Notif',
        showLocation:'Show Location',
        sendMessage:'Send Message',
        recievedProducts:'recievedProducts',
        DliveryNewProduct:'Dlivery New Product',
        buySalesMan:'Buy SalesMan',
        collectSalesMan:'Collect SalesMan',
        firstPay:'First Pay',
        premuimCost:'Premuim Cost',
        premuimsCount:'Premuims Count',
        delayPremuims:'Delay Premuims',
        payPremuims:'Paid Premuims',
        remainPremuims:'Remain Premuims',
        payCost:'Paid Cost',
        remainCost:'Remain Cost',
        cash:'Cash',
        chooseProduct:'Choose Product',
        count:'Count',
        adsViewsCount:'Ads Views Count',
        allSoldProducts:'All Sold Products',
        TotalSalesOfAllCompounds:'Total Sales Of All Compounds',
        compoundsInApp:'compounds In App',
        allUsersCount:'All Users Count',
        newUsers:'New Users',
        topSalesMan:'Top SalesMan',
        topCompound:'Top Compound',
        topProduct:'Top Sold Product',
        topProducts:'Top Sold Products',
        ads:'Ads',
        showAll:'Show All',
        dalyViews:'Daly Views',
        allViews:'All Views',
        overView:'Over View',
        statistic:'Statistic',
        viewsCount:'Views Count',
        ordersCount:'Orders Count',
        title:'Title',
        theAdd:'The Add',
        addSalesMan:'Add SalesMan',
        lastSeen:'Last seen',
        collectedClients:'Collected Clients',
        Receivables:'Receivables',
        TransferCollections:'TransferCollections',
        receiveMoney:'Receive Money',
        changeSalesMan:'changeSalesMan',
        receivedProducts:'Received Products',
        daily:'Total Daily',
        allPremiums:'All Collect',
        additionCollect:'Addition Collect',
        finishedPremiums:'Finished Premiums',
        pendingPremiums:'Pending Premiums',
        Dues:'Dues',
        recieveReceivables:'Recieve Receivables',
        money:'Money',
        date:'Date',
        to:'To',
        from:'From',
        addSupplier:'Add Supplier',
        addTransaction:'Add Transaction',
        theNaming:'Naming',
        receive:'Receive',
        give:'Give',
        cost:'Cost',
        addCost:'Add Cost',
        chooseSalesMan:'Choose SalesMan',
        chooseReceiptType:'Choose Receipt Type',
        onlyLastMonth:'Only Last Month',
        paid:'Paid',
        monthNum:'Month Num',

        storeName:'Store Name',
        subCategory:'Sub-Category',
        StoresTable:'Stores Table',
        storeAbout:'About Store',
        workTime:'Work Time',
        salesCode:'Sales Code',
        chooseSubCategory:'Choose SubCategory',
        package:'Packge',
        discount:'Discount',
        firstPackage:'First Package',
        secondPackage:'Second Package',
        chooseArea:'Choose Area',
        storeProfile:'store Info',
        updateStore:'Update Store',
        AddCategory:'Add Category',
        addSubCategory:'Add Sub Category',
        storeUsers:' Store Users ',
        priority:'Priority',
        addStoreUser:'Add Store User',
        storePhone:'Store Phone',
        storeName_ar:'Arabic Store Name',
        childUsers:'Child Users',
        addArea:'Add Area',
        addCity:'Add City',
        branches:'Branches',
        branchName:'Arabic Branch Name',
        main:'Main',
        branch:'Branch',
        chooseStoreType:'Choose Store Type',
        chooseMainStore:'Choose Main Store',
        mainStore:'Main Store',
        storeType:'Store Type',
        salesCodeType:'salesCodeType',
        self:'Self',
        branchName_en:'English Branch Name ',
        casher:"Casher",
        endPrice:'End Price',
        NoRule:'No Discount Rule',
        Stores:'Stores',
        bills:'Bills',
        home:'Home',
        clients:'Clients',
        moga:'Moga',
        rule:'Discount Rule',
        BillInfo:'Bill Info',
        canceled:'Canceled',
        addDiscountRule:'Add Discount Rule',
        rules:'Discount Rules',
        RuleInfo:'Discount Rule Info',
        updateRule:'Update Rule',
        billPassword:"bills Password",
        facebookLink:"Facebook Link",
        reNew:'Re New',
        problemType:'Problem Type',
        problem:'Problem',
        problems:'Problems',
        reports:'Reports',
        isReply:'is Reply',
        ProblemInfo:'Problem Info',
        reply:'Reply',
        SendNotif:'Send Notif',
        userType:'User Type',
        about:'About Us',
        NewsTable:'News Table',
        NewsInfo:'News Info',
        addNews:'Add News',
        news:'News',
        update:'Update',
        EventsTable:'Events Table',
        EventInfo:'Event Info',
        addEvent:'Add Event',
        Events:'Events',
        error:'Sorry Process Faild !' ,
        eventDate:'Event Date',
        loadMore:'Load More',
        faculites:'Faculites',
        years:'Years',
        AddYear:'addYear',
        YearsTable:'Year Table',
        YearInfo:'Year Info',
        FaculitesTable:'Faculites Table',
        FaculityInfo:'Faculity Info',
        addFaculty:'Add Faculty',
        yearsNum:'Years Number',
        hasDivision:'Has Division',
        contactUs:'contactUs',
        faculty:'Faculty',
        year:'Year',
        department:'Department',
        division:'Divisions',
        DivisionsTable:'Divisions Table',
        AddDivision:'Add Division',
        DivisionInfo:'Division Info', 
        Divisions:'Divisions',
        DepartmentsTable:'Departments Table',
        AddDepartment:'Add Department',
        DepartmentInfo:'Department Info',
        Departments:'Departments',
        Subjects:'Subjects',
        Subject:'Subject',
        AddSubject:'Add Subject',
        SubjectInfo:'Subject Info',
        SubjectsTable:'Subjects Table',
        isAnswer:'Is Answer',
        student:'Student',
        teacher:'Teacher',
        question:'Question',
        questions:'Questions',
        AddQuestion:'Add Question',
        QuestionInfo:'Question Info',
        answer:'Answer',
        firstname:'First Name',
        admin:'Admin',
        studentsTable:'Students Table',
        students:'Students',
        TeachersTable:'Teachers Table',
        teachers:'Teacher',
        users:'Users',
        EventsAndNews:'Events & News',
        CommunityTable:'Community Table',
        AddCommunity:'Add Community',
        CommunityInfo:'Community Info',
        owner:'Owner',
        Communities:'Communities',
        lectureNumber:'Lecture Number',
        softCopy:'Soft Copy',
        hardCopy:'Hard Copy',
        LecturesTable:'Lectures Table',
        Lectures:'Lectures',
        Lecture:'Lecture',
        LectureInfo:'Lecture Info',
        AddLecture:'Add Lecture',
        files:'Files',
        videos:'Videos',
        arabicDescription:'Arabic Description',
        englishDescription:'English Description',
        arabicTitle:'Arabic Title',
        englishTitle:'English Title',
        Schedules:'Lectures Schedules',
        AddSchedule:'Add Schedule',
        Schedule:'Schedule',
        time:'Time',
        selectTime:'Select Time',
        selectDate:'Select Date',
        ScheduleInfo:'Schedule Info',
        addMoney:'Add Money',
        balance:'Balance',
        addTeacher:'Add Teacher',
        subjectsCount:'Subjects Count',
        studentsCount:'Student Count',
        teachersCount:'Teachers Count',
        questionsCount:'Questions Count',
        messagesCount:'Messages Count',
        lecturesCount:'Lectures Count',
        topLectureBuy:'Top Lecture Buy',
        topLecture:'Top Lecture',
        topStudent:'Top Student Balance',
        topActiveTeacher:'Top Active Teacher',
        topTeacher:'Top Teacher',
        SearchAbout:'Search (deals - users)',
        buyCount:'Buy Count',
        openFile:'Open File',
        activeCode:'Active Code',
        removeDeviceData:'Remove Device Data',
        viewers:'Viewers',
        addViewers:'Add Viewers',
        upload:'Upload',
        Profile:'Profile',
        logout:'Logout',
        addViewer:'Add Viewer',
        country:'Country',
        aboutUs_en:'English About Us ',
        aboutUs_ar:'Arabic About Us ',
        AddTerms:'Add Terms',
        theActivity:'The Activity',
        dayCost:'Day Cost',
        winPoints:'Win Points',
        pointCost:'Point Cost',
        adminsTable:'Admins Table',
        AddUser:'Add User',
        Cities:'Cities',
        Terms:"Terms & Conditions",
        setting:'Setting',
        notifs:'Notifications',
        countryCode:'Country Code',
        numbersCount:'Numbers Count',
        isoCode:'ISO Code',
        CountriesTable:'Countries Table',
        Countries:'Countries',
        countryInfo:'Country Info',
        updateCountry:'Update Country',
        addCountry:'Add Country',
        hint:'Hint',
        activities:'Activities',
        admins:'Admins',
        workTime_en:'Work Time English',
        workTime_ar:'Work Time Arabic',
        whatsApp:'WhatsApp',
        website:'Web Site',
        showOn:'Show On',
        offers:'Offers',
        offerInfo:'Offer Info',
        end:'End',
        endDate:'End Date',
        logo:'Logo',
        background:'Background',
        gallery:'Gallery',
        addActivity:'Add Activity',
        latitude:'Latitude',
        longitude:"Longitude",
        topActivity:'Top Activity',
        usersCount:'Users Count',
        activityCount:'Activities Count',
        bookingCount:'Booking Count',
        offerCount:'Offer Count',
        problemCount:'Problem Count',
        topActivityRate:'Top Activity Rate',
        rate:'Rate',
        topUser:'Top user Booking',
        favActivity:'Fav Activity',
        favCount:'Fav Count',
        info:"Information",
        offer:'Offer',
        Booking:'Booking',
        bookingCode:'Booking Code',
        services:'Services',
        couponNumber:'Coupon Number',
        AddBrand:'Add Brand',
        AddSize:'Add Size',
        AddCoupon:'Add Coupon',
        theReview:'The Review',
        sizeInfo:'Size Info',
        brandInfo:'Brand Info',
        couponInfo:'Coupon Info',
        sizes:'Sizes',
        brands:'Brands',
        coupons:'Coupons',
        addColor:'Add Color',
        colorInfo:'Color Info',
        anoncementInfo:'Anoncement Info',
        addAnoncement:'Add Anoncement',
        anoncements:'Anoncements',
        Artists:'Artists',
        colors:'Colors',
        arabicQuestion:'Question in arabic',
        englishQuestion:'Question in english',
        englishAnswer:'Answer in english',
        arabicAnswer:'Answer in arabic',
        brand:'Brand',
        color:'Color',
        size:'Size',
        offerType:'Offer Type',
        Orders:'Orders',
        Search:'Search',
        online:'Online',
        finalTotal:'Final Total',
        total:'Total',
        hasPromoCode:'Has Promo Code',
        paymentSystem:'Payment System',
        PendingOrders:'Pending Orders',
        acceptedOrders:'Accepted Orders',
        deliverdOrders:'Deliverd Orders',
        refusedOrders:'Refused Orders',
        store:'COMPANIES',
        orderInfo:'Order Info',
        status:'Status',
        pending:'Pending',
        accepted:'Accepted',
        refused:'Refused',
        deliverd:'Deliverd',
        street:'Street',
        placeType:'Place Type',
        floor:'Floor',
        apartment:'Apartment',
        coupon:'Coupon',
        refuse:'Refuse',
        accept:'Accept',
        areas:'Areas',
        reason:'Reason',
        freeShipping:'FreeShipping',
        verifyRequests:'Verify Requests',
        artist:'Artist',
        artistsCount:'Artists Count',
        adminCount:'Admin Count',
        anoncementCount:'Anoncement Count',
        productsCount:'Products Count',
        pendingOrdersCount:'Pending Orders Count',
        topUserRate:'Top User Rate',
        topProductSale:'Top Product Sale',
        topProductRate:'Top Products Rate',
        topProductsRate:'Top Products Rate',
        saleCount:'Sales Count',
        discountType:'Discount Type',
        fixed:'Fixed Money',
        expireDate:'Expire Date',
        ended:'Ended',
        rejected:'Rejected',
        startTime:"StartTime",
        endTime:"EndTime",
        liveInfo:'Live Info',
        lives:'Lives',
        reject:'Reject',
        bookings:'Bookings',
        bookingInfo:'Booking Info',
        completed:'Completed',
        personsCount:'personsCount',
        service:'Service',
        shipping:'Shipping',
        free:'Free',
        notFree:'Not Free',
        notes:'Notes',
        theViewers:'The Viewers',
        durationSec:'Duration(Sec)',
        linkType:'Link Type',
        inside:'Inside',
        outside:'Outside',
        maxCities:'Allowed Cities',
        searchKm:'Search Space (KM)',
        androidAppVersion:'Android Version',
        iosAppVersion:'Ios Version',
        adsCost:'Ads Cost',
        taxRatio:'Tax Ratio',
        expireAfter:'Expiration',
        shareTitle_en:'English Share Title ',
        shareTitle_ar:'Arabic Share Title',
        shareDescription_en:'English Share Description',
        shareDescription_ar:'Arabic Share Description',
        facebook:'Facebook',
        twitter:'Twitter',
        snapChat:'SnapChat',
        youtube:'Youtube',
        instagram:'Instagram',
        androidAppLink:'Android App Link',
        iosAppLink:'Ios App Link',
        privacy_ar:'Arabic Privacy',
        privacy_en:'English Privacy',
        terms_ar:'Arabic Terms',
        terms_en:'English Terms',
        number:'Number',
        string:'String',
        boolean:'Boolean',
        list:'List',
        englishOption:'English Option',
        arabicOption:'Arabic Option',
        FeatureInfo:'Feature Info',
        AddFeature:'Add Feature',
        addRegion:'Add Region',
        regions:'Regions',
        regionInfo:'Region Info',
        Transactions:'Transactions',
        Features:'Features',
        Packages:'Packages',
        archivedAds:'Archived Ads After?',
        adsRememberDays:'Remember User About Ads After?',
        adsManagment:'Ads Management',
        propertyManagement:'Property Managment',
        availableAds:'Available Ads',
        plan:'Plan Ad',
        supervisors:'Supervisors',
        beDefault:'Be Default',
        durationType:'Duration Type',
        monthly:'Monthly',
        yearly:'Yearly',
        AddPackage:'Add Package',
        PackageInfo:'Package Info',
        offices:'Offices',
        accountType:'Account Type',
        notActive:'Not Active',
        Offices:'Offices',
        approved:'Approved',
        disApproved:'Dis-Approved',
        unBlock:'UnBlock',
        office:'Office',
        blocked:'Blocked',
        adsCount:'Ads Count',
        adsRequestCount:'Ads Request Count',
        contactRequestCount:' Contact Request Count',
        isVerified:'Is Verified',
        adsRequests:'Ads Requests',
        approvedOfficesCount:' Approved Offices',
        notApprovedOfficesCount:' Not Approved Offices',
        contractType:'Contact Type',
        notAvailable:'Not Available',
        sale:'Sale',
        rent:'Rent',
        workCity:'Work City',
        workArea:'Work Area',
        workCategory:'Work Category',
        workSubCategory:'Work Sub Category',
        agencyType:'agency Type',
        marketer:'Marketer',
        freeWork:'FreeWork',
        arabicBestFeatures:'Arabic Best Features',
        englishBestFeatures:' English Best Features',
        englishAddress:'English Address',
        arabicAddress:' Arabic Address',
        enableInstallment:'Enable Installment',
        enablePhoneContact:"Enable Phone Contact",
        contraindicationsDesc: "Contraindications Desc",
        undocumentedRightsDesc: "Undocumented Rights Desc",
        influentialInformationDesc: "Influential Information Desc",
        guaranteesDesc: "Guarantees Desc",
        commissionNumber: "Commission Number",
        contraindications: "Contraindications",
        undocumentedRights:"Undocumented Rights",
        influentialInformation: "Influential Information",
        saudiBuildingCode: "SaudiBuilding Code",
        guarantees: "Guarantees",
        advertiserClass: "Advertiser Class",
        deposit:"Deposit",
        disputes:"Disputes",
        disputesDesc:"Disputes Desc",
        commissioner:'Commissioner',
        subFeatures:"Sub Features",
        mainFeatures:"Main Feature",
        notExist:'Not Exist',
        priceTo:"Price To",
        priceFrom:"Price From",
        sizeTo:"Size To",
        sizeFrom:"Size From",
        loading:'Loading...',
        uploadFile:'Upload File',
        more:'More..',
        completedAdsRequest:'Completed Ads Request',
        canceledAdsRequest:'Canceled Ads Request',
        onProgressAdsRequest:'On Progress Ads Request',
        subAdmin:'SUB-ADMIN',
        img:'Image',
        createdAt:'Created At',
        noProgress:'On Progress',
        new:'New',
        filterSearch:'Search by title - ownerID',
        requiredInput:'Required Input',
        correct:'Correct',
        notApproved:'Not Approved',
        clientCount:'Client Count',
        officeCount:' Office Count',
        reactions:'Reactions',
        reactionType:'Reaction Type',
        signUpProgcess:'Sign Up Progcess',
        permissions:'Permissions',
        commonds:'Commonds',
        permissionInfo:'PermissionInfo',
        addPermission:'Add Permission',
        hasAdsPackage:'Has Ads Package',
        hasPropertyPackage:'Has Property Package',
        notCompletedAdsRequest:'Not Completed Ads Request',
        dashboard:'Dashboard',
        onProgress:'On Progress',
        permission:'Permission',
        social:'Social',
        occasionType:'Occasion Type',
        public:'Public',
        private:'Private',
        questionnaires:'Questionnaires',
        seenCount:'Seen Count',
        participantsCount:'Participants Count',
        answerType:'Answer Type',
        chooseOne:'Choose One',
        chooseMultiple:'Multi Choose',
        article:'Article',
        options:'Options',
        option:'Option',
        choosenCount:"Choosen Count",
        choosenRatio:"Choosen Ratio",
        gender:'Gender',
        birthday:'Birthday',
        questionnaireInfo:'Questionnaire Info',
        addQuestionnaire:'Add Questionnaire',
        arabicOptionName:'Arabic Option Name',
        englishOptionName:'English option Name',
        questionnaireOn:'Questionnaire On',
        male:'Male',
        female:'Female',
        contacts:'Contacts',
        gifts:'Gifts',
        occasions:'Occasions',
        reActive:'ReActive',
        occasionSearch:'Search Using Occasion Number - Owner Number',
        occasionInfo:'Occasion Info',
        repetition:'Repetition',
        giftInfo:'Gift Info',
        given:'Given',
        booked:'Booked',
        bookedUser:'Booked User',
        questionnaireCount:'Questionnaire Count',
        lastUsers:'Last Users',
        sencondLang:'Sencond Lang',
        giftsearch:'Search Using Gift Number - Owner Number',
        relatedToOccasion:'Related To Occasion',
        participant:'Participant',
        answers:'Answers',
        outerClientRatio:'Outer Client Ratio',
        DSPRatio:'DSP Ratio',
        AddLocation:' Add Location',
        locations:' Locations',
        locationInfo:' Location Info',
        AddPoint:'Add Point',
        availableLeads:'Available Leads',
        points:'Points',
        isp:'ISP',
        dsp:'DSP',
        developer:'Developer',
        checkRequests:'Check Requests',
        Clients:'Clients',
        Deals:'DEALS',
        location:'Location',
        yapClient:'Yap Client',
        outerClient:'Outer Client',
        clientName:'Client Name',
        clientPhone:' Client Phone',
        salesName:' Sales Name',
        salesPhone:' Sales Phone',
        value:'Value',
        clientType:' Client Type',
        ispCount:'ISP Count',
        dspCount:'DSP Count',
        developersCount:'Developer Count',
        dealCount:'Deals Count',
        clientsCount:'Clients Count',
        propertyCount:'Property Count',
        earns:'Earns',
        readyToUpload:'Ready To Upload',
        restLeads:'Rest Leads',
        compoundName:'Compound Name',
        pricePerMeter:'Price Per Meter',
        priceType:' Pay Type',
        downPayment:' Down Payment',
        installmentDuration:' Installment Duration',
        compoundImg:' Compound Image',
        pdf:'PDF',
        imgs:'Images',
        employeName:'Employee Name',
        employePhone:'Employee Phone',
        developerName:'Developer Name',
        addCompound:'Add Compound',
        closedDate:'Closed Date',
        developerPoint:'Developer point',
        payCommission:'Pay Commission',
        dealCommission:'Deal Commission',
        dspCommission:'DSP Commission',
        packageRatio:'Package Ratio',
        addProperty:'Add Property',
        yapClientRatio:'Yap Client Ratio',
        interestedPackage:'Interesting Package',
        assignedTo:'Assigned To',
        user:'User',
        totalEarns:'Total Earns',
        acceptedDeals:'Accepted Deals',
        pendingDeals:'Pending Deals',
        finishingType:'Finishing Type',
        deliveryStatus:'Delivery Status',
        readyToMove:'Ready To Move',
        fullyFinished:'Fully Finished',
        coreShell:'Core & Shell',
        afterTwoYears:'After Two Years',
        afterThreeYears:'After Three Years',
        age:'Age',
        experience:'Expertience',
        arabicSpecialization:'Arabic Specialization',
        englishSpecialization:'English Specialization',
        arabicStrengthPoints:'Arabic Strength Points',
        englishStrengthPoints:'English Strength Points',
        companyLogo:'Company Logo',
        byitTeam:'byitTeam',
        arabicProffession:'Arabic Proffession',
        englishProffession:'English Proffession',
        theArea:'The Area',
        villas:'Villas',
        apartments:'Apartments',
        mall:'Mall',
        unitCode:'Unit Code',
        views:'Views',
        duration:'Duration in sec',
        yearsExperience:"Years Experience",
        called:"Called",
        addedBy:'Added By',
        nawyApp:'Nawy App',
        informClient:'Dont Inform the Client?',
        dealCost:'Deal Cost',
        close:'Close',
        closed:'Closed',
        closeClient:'Close Client',
        showTop:'Show on Top',
        guidelines:'Guidelines',
        thumbnail:'Thumbnail',
        video:'Video',
        uploadVideo:'Upload Video',
        customerService:'Customer Service',
        cilPhone:'Cil',
        closingSupportPhone:'Closing Support',
        contractPhone:'Contract',
        coveragePhone:'Coverage',
        company:'Company',
        companies:'Companies',
        provement:'Provement',
        newLaunches:'New Launches',
        bookingPrice:'Booking Price',
        addBalance:'Add Balance',
        diffOnspotRatio:'OnSpot Ratio',
        hideInApp:'Hide In App',
        showInApp:'Show In App',
        paymentPlan:'Payment Plan',
        acceptAll:'Accept All',
        showNormal:'Show Normal',
        joinDate:'Join Date',
        voiceNote:'Voice Note',
        newLaunch:'New Launch',
        propertyId:'Property Id',
        newLaunchId:'new Launch Id',
        normalAnoncement:'No Reference',
        enableTargetAchievement:'Enable Target Achievement',
        targetStartDate:'Target Start Date',
        targetEndDate:'Target End Date',
        targetPoints:'Target Points',
        forceUpdate:'Force Update',
        prizes:'Prizes',
        prize:'Prize',
        targetAchievementLogo:'Target Achievement Logo',
        targetAchievementBackground:'Target Achievement Background',
        targetAchievementInfo:'Target Achievement Info',
        calledAll:'Called All',
        onSpot:'OnSpot',
        pinCode:'Pin Code',
        companyRatio:'Company Ratio',
        invitationCode:'invitation Code',
        networkEarns:'Network Earns',
        totalNetworkEarns:'Total Network Earns',
        totalNetworkDeals:'Total Network Deals',
        payNetworkCommission:'Pay Network Commission',
        userNetworks:'User Networks',
        level:'Level'

    },
    ar: {
        level:'المستوى',
        userNetworks:'User Networks',
        payNetworkCommission:'Pay Network Commission',
        invitationCode:'كود الدعوه',
        networkEarns:'Network Earns',
        totalNetworkEarns:'Total Network Earns',
        totalNetworkDeals:'Total Network Deals',
        companyRatio:'النسبه للشركات',
        pinCode:'الرقم السرى للشركات',
        onSpot:'الخصم الفورى',
        calledAll:'تم التواصل للكل',
        targetAchievementLogo:'لوجو صفحه الانجاز',
        targetAchievementBackground:'خلفيه صفحه الانجاز',
        targetAchievementInfo:'معلومات صفحه الانجاز',
        enableTargetAchievement:'تفعيل الانجازات',
        targetStartDate:'تاريخ بدايه الانجاز',
        targetEndDate:'تاريخ نهايه الانجاز ',
        targetPoints:'مراحل الانجازات',
        forceUpdate:'تحديث اجبارى',
        prizes:'الجوائز',
        prize:'الجائزه',
        normalAnoncement:'بدون رابط',
        newLaunchId:'رقم المشروع',
        propertyId:'رقم العقار',
        newLaunch:'مشروع الجديده',
        voiceNote:'تسجيل صوتى',
        joinDate:'تاريخ الانضمام',
        showNormal:'يعرض بشكل تلقائى',
        acceptAll:'قبول الكل',
        paymentPlan:'جدول التقسيط',
        showInApp:'عرض بالتطبيق',
        hideInApp:'اخفاء من التطبيق',
        diffOnspotRatio:'فرق الخصم الفورى',
        addBalance:'اضافه رصيد',
        bookingPrice:'سعر الحجز',
        newLaunches:'المشاريع الجديده',
        provement:'الاثبات',
        company:'الشركه',
        companies:'الشركات',
        customerService:'رقم خدمه العملاء',
        cilPhone:'cil رقم هاتف ',
        closingSupportPhone:'رقم هاتف الصفقات',
        contractPhone:'رقم هاتف التعاقد',
        coveragePhone:'رقم هاتف التغطيات',
        uploadVideo:'رفع الفيديو',
        video:'فيديو',
        thumbnail:'صوره توضيحيه',
        guidelines:'الخطوات التعريفيه',
        showTop:'يعرض بالاعلى ',
        dealCost:'قيمه الصفقه',
        close:'اغلاق',
        closed:'تم الاغلاق',
        closeClient:'اغلاق العميل',
        informClient:'لا تبلغ العميل؟',
        nawyApp:'تطبيق ناوى',
        addedBy:'أضيف بواسطه',
        called:"تم الاتصال",
        yearsExperience:'سنوات الخبره',
        views:'المشاهدات',
        duration:'المده بالثوانى',
        unitCode:'رقم الوحده',
        villas:'فيلات',
        apartments:'شقق سكنيه',
        mall:'مول تجارى',
        theArea:"المساحه",
        arabicProffession:'الوظيفه باللغه العربيه',
        englishProffession:'الوظيفه باللغه الانجليزيه',
        age:'السن',
        experience:'الخبره',
        arabicSpecialization:'التخصص باللغه العربيه',
        englishSpecialization:'التخصص باللغه الانجليزيه',
        arabicStrengthPoints:'نقاط القوه باللغه العربيه',
        englishStrengthPoints:'نقاط القوه باللغه الانجليزيه',
        companyLogo:'لوجو الشركه',
        byitTeam:'فريق بايت',
        afterTwoYears:'بعد عامين',
        afterThreeYears:'بعد ثلاث اعوام',
        finishingType:'نوع التشطيب',
        deliveryStatus:'التسليم',
        readyToMove:'استلام فورى',
        fullyFinished:'تشطيب كامل',
        coreShell:'بدون تشطيب',
        acceptedDeals:'الصفقات المكتمله',
        pendingDeals:'الصفحات بالانتظار',
        totalEarns:'اجمالى الربح',
        user:'المستخدم',
        assignedTo:'المكلفين',
        interestedPackage:'مهتم بالباقه',
        yapClientRatio:'نسبه العميل الداخلى',
        addProperty:'اضافه عقار',
        packageRatio:'نسبه الباقه',
        dspCommission:'عموله ال DSP',
        dealCommission:'العموله من الصفقه',
        payCommission:'دفع عموله',
        developerPoint:'نقاط المطور',
        closedDate:'تاريخ الاغلاق',
        addCompound:'اضافه مجمع سكنى',
        developerName:'اسم المطور',
        employeName:'اسم الموظف',
        employePhone:'رقم هاتف الموظف',
        imgs:'الصور',
        pdf:'ملف العقار',
        compoundImg:'صوره المجمع السكنى',
        priceType:'نوع الدفع',
        downPayment:'المقدم',
        installmentDuration:'مده القسط',
        pricePerMeter:'سعر المتر',
        compoundName:' اسم المجمع السكنى',
        restLeads:'استعاده العملاءالمتاحين',
        readyToUpload:'الملف جاهز للرفع ',
        earns:'الارباح',
        propertyCount:'عدد العقارات',
        clientsCount:'عدد العملاء',
        dealCount:'عدد الصفقات',
        developersCount:' عدد الديفيلوبرز',
        ispCount:'عدد الISP',
        dspCount:'عدد الDSP',
        clientType:'نوع العميل',
        value:'القيمه',
        salesPhone:'رقم البائع',
        salesName:'اسم البائع',
        clientPhone:'رقم العميل',
        clientName:'اسم العميل',
        outerClient:'عميل خارجى',
        yapClient:'عميلنا',
        location:'الموقع',
        Deals:'الصفقات',
        Clients:'العملاء',
        checkRequests:'طلب تحصيل',
        isp:'ISP',
        dsp:'DSP',
        developer:'Developer',
        points:'النقاط',
        availableLeads:'عدد العملاء',
        AddPoint:'اضافه نقاط',
        locationInfo:'بيانات الموقع',
        locations:'المواقع',
        AddLocation:'اضافه موقع',
        DSPRatio:'نسبه الDSP',
        outerClientRatio:'النسبه للعميل الخارجى',
        answers:'الاجابات',
        participant:'المشارك',
        relatedToOccasion:'مرتبط بمناسبه',
        giftsearch:'بحث بواسطه رقم الهديه - رقم المالك',
        sencondLang:'اللغه الثانيه',
        lastUsers:'اخر المسجلين',
        questionnaireCount:'عدد الاستطلاعات',
        bookedUser:'تم الحجز بواسطه',
        given:'تم الاهداء',
        booked:'تم الحجز',
        giftInfo:'بيانات الهديه',
        repetition:'التكرار',
        occasionInfo:'بيانات المناسبه',
        occasionSearch:'ابحث بواسطه رقم المناسبه - رقم المالك',
        reActive:'اعاده تنشيط',
        gifts:'الهدايا',
        occasions:'المناسبات',
        contacts:'جهات الاتصال',
        male:'ذكر',
        female:'انثى',
        questionnaireOn:'استطلاع على',
        englishOptionName:'الاختيار باللغه الانجليزيه',
        arabicOptionName:'الاختيار باللغه العربيه',
        addQuestionnaire:'اضافه استطلاع',
        questionnaireInfo:'بيانات الاستطلاع',
        birthday:'تاريخ ميلاد',
        gender:'الجنس',
        choosenRatio:'عدد مرات الاختيار',
        choosenCount:'عدد مرات الاختيار',
        option:'الاختيار',
        options:'الاختيارات',
        article:'مقالى',
        chooseMultiple:'اختيار متعدد',
        chooseOne:'اختر اجابه واحده',
        answerType:'نوع الاجابه',
        participantsCount:'عدد المشاركين',
        seenCount:'عدد المشاهدات',
        questionnaires:'الاستطلاعات',
        private:'خاص',
        public:'عام',
        occasionType:'نوع المناسبه',
        social: 'منصات التواصل ',
        permission:'الصلاحيه',
        onProgress:'تحت الطلب',
        dashboard:'الصفحه الرئيسيه',
        notCompletedAdsRequest:'طلبات غير مكتمله',
        hasPropertyPackage:'لديه باقه اداره املاك',
        hasAdsPackage:'لديه باقه اعلانات',
        addPermission:'اضافه صلاحيه',
        permissionInfo:'بيانات الصلاحيه',
        commonds:'الاوامر',
        permissions:'الصلاحيات',
        signUpProgcess:'لم يكمل التسجيل',
        reactionType:'نوع التفاعل',
        reactions:'التفاعلات',
        officeCount:'عدد المكاتب',
        clientCount:'عدد العملاء',
        notApproved:'تحت المراجعه',
        correct:'صحيح',
        requiredInput:'الحقل مطلوب',
        filterSearch:'بحث بواسطه العنوان - رقم العميل',
        new:'جديد',
        noProgress:'جارى التنفيذ',
        createdAt:'تاريخ الاضافه',
        img:'صوره',
        subAdmin:'مشرف',
        onProgressAdsRequest:'طلبات تحت الاجراء',
        canceledAdsRequest:'طلبات ملغاه',
        completedAdsRequest:'طلبات مكتمله',
        more:'.. المزيد',
        uploadFile:'رفع ملف',
        loading:'تحميل ..',
        priceTo:"اعلى سعر",
        priceFrom:"اقل سعر",
        sizeTo:"اكبر مساحه",
        sizeFrom:"اقل مساحه",
        notExist:'لا يوجد',
        subFeatures:'المميزات الفرعيه',
        mainFeatures:'المميزات الرئيسيه',
        commissioner:'مفوض',
        enablePhoneContact:"اتاحه رقم الهاتف",
        contraindicationsDesc: "تفاصيل القيود ",
        undocumentedRightsDesc: "الحقوق الغير موثقه",
        influentialInformationDesc: "التفاصيل المؤثره ",
        guaranteesDesc: "الضمانات",
        commissionNumber: "رقم التفويض",
        contraindications: "هل يوجد قيود على الاعلان؟",
        undocumentedRights:"هل يوجد حقوق غير مؤثقه؟",
        influentialInformation:"هل يوجد مايؤثر على الاعلان؟",
        saudiBuildingCode: "متوافق مع كود البناء السعودى",
        guarantees: "هل يوجد ضمانات؟",
        advertiserClass: "صفه المعلن",
        deposit:"العربون",
        disputes:"هل يوجد نزاعات قائمه؟",
        disputesDesc:"تفاصيل النزاعات",
        enableInstallment:'متاح للتقسيط',
        arabicAddress:'العنوان باللغه العربيه',
        englishAddress:'العنوان باللغه الانجليزيه',
        englishBestFeatures:'افضل المميزات باللغه الانجليزيه',
        arabicBestFeatures:'افضل المميزات باللغه العربيه',
        marketer:'مسوق عقارى',
        freeWork:'حاصل على وثيقه عمل حر',
        agencyType:'جهة المعلن',
        workSubCategory:'اقسام العمل الفرعيه',
        workCategory:'اقسام العمل',
        workArea:'مناطق العمل',
        workCity:'مدن العمل',
        sale:'بيع',
        rent:'ايجار',
        notAvailable:'غير متاح',
        contractType:'نوع التعاقد',
        notApprovedOfficesCount:'مكاتب فى انتظار التنشيط',
        approvedOfficesCount:'المكاتب النشطه',
        adsRequests:'الطلبات العقاريه',
        isVerified:'مؤكد',
        contactRequestCount:'عدد طلبات التواصل',
        adsRequestCount:'عدد الطلبات العقاريه',
        adsCount:'عدد الاعلانات',
        blocked:'محظور',
        office:'مكتب',
        unBlock:'ازاله الحظر',
        disApproved:'الرفض',
        approved:'الموافقه',
        Offices:'المكاتب',
        notActive:'غير مفعل',
        accountType:'حاله الحساب',
        offices:'المكاتب',
        AddPackage:'اضافه باقه',
        PackageInfo:'بيانات الباقه',
        monthly:'شهريا',
        yearly:'سنويا',
        durationType:'نوع المده',
        beDefault:' تعيين كافتراضى',
        plan:'مخطط',
        supervisors:'مشرفين',
        availableAds:'الاعلانات المتاحه',
        propertyManagement:'اداره املاك',
        adsManagment:'اداره اعلانات',
        archivedAds:'ارشفه الاعلانات بعد؟',
        adsRememberDays:'تذكير المستخدم بالاعلان بعد؟',
        Packages:'الباقات',
        Features:'المميزات',
        Transactions:'التحويلات الماليه',
        addRegion:'اضافه منطقه',
        regions:'المناطق',
        regionInfo:'تفاصيل المنطقه',
        AddFeature:'اضافه ميزه',
        FeatureInfo:'تفاصيل الميزه',
        englishOption:'الاختيار بالانجليزيه',
        arabicOption:'الاختيار بالعربيه',
        number:'رقميه',
        string:'نصيه',
        boolean:'اختر',
        list:'قائمه',
        privacy_ar:'سياسه التطبيق باللغه العربيه',
        privacy_en:'سياسه التطبيق باللغه الانجليزيه',
        terms_en:'الشروط والاحكام باللغه الانجليزيه',
        terms_ar:'الشروط والاحكام باللغه العربيه',
        shareTitle_en:'عنوان المشاركه بالانجليزيه',
        shareTitle_ar:'عنوان المشاركه بالعربيه',
        shareDescription_en:'تفاصيل المشاركه بالانجليزيه',
        shareDescription_ar:'تفاصيل المشاركه بالعربيه',
        facebook:'فيسبوك',
        twitter:'تويتر',
        snapChat:'سناب شات',
        youtube:'يوتيوب',
        instagram:'انستجرام',
        androidAppLink:'رابط تطبيق الاندرويد',
        iosAppLink:'رابط تطبيق الايفون',
        maxCities:'عدد المدن المسموحه',
        searchKm:'مساحه البحث (كم)',
        androidAppVersion:'اصدار الاندرويد',
        iosAppVersion:'اصدار الايفون',
        adsCost:'تكلفه الاعلان',
        taxRatio:'نسبه الضريبه',
        expireAfter:'الانتهاء بعد',
        linkType:'نوع الرابط',
        inside:'داخلى',
        outside:'خارجى',
        durationSec:'المده (ثانيه)',
        theViewers:'المشاهدين',
        notes:'تفاصيل',
        notFree:'غير مجانى',
        free:'مجانى',
        shipping:'الشحن',
        service:'الخدمه',
        personsCount:'عدد الافراد',
        completed:'مكتمل',
        bookingInfo:'تفاصيل الحجز',
        bookings:'الحجوزات',
        reject:'رفض',
        lives:'البث',
        liveInfo:'تفاصيل البث',
        endTime:'وقت النهايه',
        startTime:'وقت البدايه',
        rejected:'مرفوض',
        ended:'انتهى',
        discountType:' نوع الخصم',
        fixed:'مبلغ ثابت',
        expireDate:' تاريخ الانتهاء',
        saleCount:'عدد المبيعات',
        artistsCount:'عدد خبراء التجميل',
        adminCount:'عدد الادمنز',
        anoncementCount:'عدد الاخبار',
        productsCount:'عدد المنتجات',
        pendingOrdersCount:'الطلبات المنتظره',
        topUserRate:' اعلى مستخدم تقييما',
        topProductSale:'الاكثر مبيعا',
        topProductRate:'اعلى منتج تقييما',
        topProductsRate:'المنتجات الاعلى تقييم',
        artist:'خبير تجميل',
        verifyRequests:'طلبات التأكيد',
        freeShipping:'توصيل مجانى',
        reason:'السبب',
        areas:'الاحياء',
        accept:'قبول',
        refuse:'رفض',
        coupon:'كود الخصم',
        apartment:'الشقه',
        floor:'الطابق',
        placeType:'نوع المكان',
        street:'الشارع',
        pending:'تحت الانتظار',
        accepted:' تم الموافقه',
        refused:'مرفوض',
        deliverd:'تم التوصيل',
        status:'الحاله',
        orderInfo:'تفاصيل الطلب',
        store:'المتجر',
        refusedOrders:'طلبات مرفوضه',
        deliverdOrders:'طلبات تم توصيلها',
        acceptedOrders:' جارى تسليمها',
        PendingOrders:'طلبات معلقه',
        hasPromoCode:'لديه كوبون',
        paymentSystem:'نوع الدفع',
        finalTotal:'التكلفه النهائيه',
        total:"التكلفه",
        online:'اون لاين',
        Search:'بــحــث',
        Orders:'الطلبات',
        offerType:'نوع العرض',
        size:'الحجم',
        color:'اللون',
        brand:'العلامه التجاريه',
        englishAnswer:'الاجابه باللغه الانجليزيه',
        arabicAnswer:'الاجابه باللغه العربيه',
        englishQuestion:'السؤال بالانجليزيه',
        arabicQuestion:'السؤال بالعربيه',
        colors:'الالوان',
        Artists:'خبراء التجميل',
        anoncements:'الاعلانات المصوره',
        addAnoncement:'اضافه اعلان',
        anoncementInfo:'تفاصيل الاعلان',
        colorInfo:'بيانات اللون',
        addColor:'اضافه لون',
        sizes:'الاحجام',
        coupons:'الكوبونات',
        brands:'العلامات التجاريه',
        sizeInfo:'بيانات الحجم',
        brandInfo:'بيانات العلامه التجاريه',
        couponInfo:'بيانات الكوبون',
        theReview:'التعليق',
        AddCoupon:'اضافه كوبون',
        AddSize:'اضافه حجم',
        AddBrand:'اضافه علامه تجاريه',
        couponNumber:'رقم الكوبون',
        services:'خدمات',
        bookingCode:'كود الحجز',
        Booking:'الحجوزات',
        offer:'العرض',
        info:'البيانات',
        favCount:'عدد التفضيلات',
        favActivity:'النشاط المفضل',
        topUser:'اكثر العملاء حجزا',
        rate:'التقييم',
        topActivityRate:'اعلى الانشطه تقييما',
        problemCount:'عدد البلاغات',
        offerCount:'عدد العروض',
        bookingCount:'عدد الحجوزات',
        activityCount:'عدد النشاطات',
        usersCount:'عدد المستخدميين',
        topActivity:'افضل النشاطات',
        longitude:'خط الطول',
        latitude:'خط العرض',
        addActivity:'اضافه نشاط',
        gallery:'الصور',
        logo:'لوجو',
        background:'بالك جراوند',
        endDate:'تاريخ النهايه',
        end:'انتهى',
        addOffer:'اضافه عرض',
        offerInfo:'تفاصيل العرض',
        offers:'العروض',
        showOn:'مكان العرض',
        website:'الموقع الالكترونى',
        whatsApp:'واتساب',
        workTime_ar:'اوقات العمل باللغه العربيه',
        workTime_en:'اوقات العمل بالانجليزيه',
        admins:'الادمنز',
        activities:'الانشطه',
        hint:'تفاصيل',
        addCountry:'اضافه بلد',
        updateCountry:'تعديل البلد',
        countryInfo:'تفاصيل البلد',
        Countries:'البلاد',
        CountriesTable:'جدول البلاد',
        isoCode:'كود ايزو',
        countryCode:'كود الدوله',
        numbersCount:'عدد الارقام',
        notifs:'الاشعارات',
        setting:'الاعدادات',
        Terms:'الشروط والاحكام',
        Cities:'المدن',
        AddUser:'اضافه مستخدم',
        usersTable:'جدول المستخدميين',
        adminsTable:'جدول الادمنز',
        pointCost:'تكلفه النقطه',
        winPoints:'نقاط الربح',
        dayCost:'تكلفه اليوم',
        theActivity:'النشاط',
        AddTerms:'اضافه الشروط والاحكام',
        aboutUs_en:'من نحن باللغه الانجليزيه',
        aboutUs_ar:'من نحن باللغه العربيه',
        country:'البلد',
        addViewer:'اضافه مشاهد',
        logout:'الخروج',
        Profile:'الملف الشخصى',
        upload:'رفع الصوره',
        viewers:'المسموح لهم بالمشاهده',
        addViewers:'اضافه مشاهدين',
        removeDeviceData:'مسح بيانات الجهاز',
        activeCode:'كود التفعيل',
        openFile:'عرض الملف',
        buyCount:'عدد مرات الشراء',
        SearchAbout:' بحث عن صفقات - مستخدمين',
        topTeacher:'انشط المعلمين',
        topActiveTeacher:'الاكثر نشاطا',
        topLectureBuy:'الاكثر مبيعا',
        topLecture:'الاكثر مبيعا',
        topStudent:'اعلى الطلاب رصيد',
        subjectsCount:'عدد المواد',
        studentsCount:'عدد الطلاب',
        teachersCount:'عدد المعلمين',
        questionsCount:'عدد الاسأله',
        lecturesCount:'عدد المحاضرات',
        messagesCount:'عدد الرسائل',
        addTeacher:'اضافه معلم',
        balance:'الرصيد',
        addMoney:'اضافه مبلغ',
        ScheduleInfo:'بيانات الجدول',
        selectDate:'اختر التاريخ',
        selectTime:'اختر الوقت',
        time:'الوقت',
        Schedule:'الجدول',
        AddSchedule:'اضافه جدول محاضره',
        Schedules:'جداول المحاضرات',
        arabicDescription:'التفاصيل بالعربيه',
        englishDescription:'التفاصيل بالانجليزيه',
        arabicTitle:'العنوان بالعربي',
        englishTitle:'العنوان بالانجليزيه',
        videos:'مقاطع الفيديو',
        files:'الملفات',
        lectureNumber:'رقم المحاضره',
        softCopy:'Pdf نسخه ',
        hardCopy:'نسخه مطبوعه',
        LecturesTable:'جدول المحاضرات',
        Lectures:'المحاضرات',
        Lecture:'المحاضره',
        LectureInfo:'بيانات المحاضره',
        AddLecture:'اضافه محاضره',
        Communities:'المنشوارات',
        CommunityTable:'جدول المنشورات',
        AddCommunity:'اضافه منشور',
        CommunityInfo:'بيانات المنشور',
        owner:'المالك',
        EventsAndNews:'اخبار واحداث',
        users:'المستخدمين',
        teachers:'المعلمين',
        TeachersTable:'جدول المعلمين',
        students:'الطلاب',
        studentsTable:'جدول الطلاب',
        admin:'أدمن',
        firstname:'الاسم الاول',
        lastname:'الاسم الاخير',
        answer:'الاجابه',
        QuestionInfo:'بيانات السؤال',
        AddQuestion:'اضافه سؤال',
        questions:'الاسأله',
        question:'السؤال',
        student:'الطالب',
        teacher:'المعلم',
        isAnswer:'تمت الاجابه؟',
        Subjects:'المواد الدراسيه',
        Subject:'الماده الدراسيه',
        AddSubject:'اضافه ماده دراسيه',
        SubjectInfo:'بيانات الماده الدراسيه',
        SubjectsTable:'جدول المواد الدراسيه',
        AddDepartment:'اضافه قسم',
        DepartmentInfo:'بيانات القسم',
        Departments:'الاقسام',
        DepartmentsTable:'جدول الاقسام',
        Divisions:'الشعب',
        faculty:'الكليه',
        year:'السنه ',
        department:'القسم',
        division:'الشعبه',
        DivisionsTable:'جدول الشعب',
        AddDivision:'اضافه شعبه',
        DivisionInfo:' بيانات الشعبه', 
        contactUs:'تواصل معنا',
        FaculitesTable:'جدول الكليات',
        FaculityInfo:'بيانات الكليه',
        addFaculty:'اضافه كليه',
        yearsNum:'عدد السنوات',
        hasDivision:'لديها شعب',
        YearInfo:'بيانات السنه',
        YearsTable:'جدول السنه',
        AddYear:'اضافه سنه',
        years:'السنوات',
        faculites:'الكليات',
        loadMore:'المزيد',
        eventDate:'تاريخ الحدث',
        error:'! عفوا فشل فى اتمام الطلب',
        EventsTable:'جدول الاحداث',
        EventInfo:'بيانات الحدث',
        addEvent:'اضافه حدث',
        Events:'الاحداث',
        update:'تعديل',
        news:'الاخبار',
        addNews:'اضافه خبر',
        NewsInfo:'بيانات الخبر',
        NewsTable:'جدول الاخبار',
        about:'من نحن',
        userType:'نوع المستخدم',
        SendNotif:'ارسال اشعار',
        reply:'الرد',
        ProblemInfo:'بيانات المشكله',
        isReply:'تم الرد',
        problems:'المشاكل',
        reports:'البلاغات',
        problemType:' نوع المشكله',
        problem:'المشكله',
        reNew:'اعاده تعيين',
        billPassword:"باسورد الفواتير",
        facebookLink:"صفحه الفيسبوك",
        updateRule:'تعديل البند',
        RuleInfo:'بيانات بند الخصم',
        rules:'بنود الخصم',
        addDiscountRule:'اضافه بند خصم',
        canceled:'ملغاه',
        BillInfo:'بيانات الفاتوره',
        rule:'بند الخصم',
        moga:'موجا', 
        clients:'العملاء',
        home:'الصفحه الرئيسيه',
        bills:'الفواتير',
        Stores:'الاماكن',
        NoRule:'لايوجد بند خصم',
        endPrice:'السعر النهائى',
        casher:'كاشير',
        branchName_en:'اسم الفرع بالانجليزيه',
        self:'ذاتى',
        salesCodeType:"نوع كود البيع",
        chooseStoreType:'اختر نوع الفرع',
        chooseMainStore:'اختر الفرع الرئيسى',
        mainStore:'الفرع الرئيسى',
        storeType:'نوع الفرع',
        branch:'فرع',
        main:'رئيسى',
        branchName:' اسم الفرع بالعربيه',
        branches:'الفروع',
        addCity:'اضافه مدينه',
        addArea:'اضافه حى',
        childUsers:'المدعوون',
        storePhone:' هاتف المحل',
        storeName_ar:'اسم المحل بالعربيه',
        addStoreUser:'اضافه مستخدم للمحل',
        priority:'الاولويه',
        storeUsers:' مستخدمين المحل ',
        addSubCategory:'اضافه قسم فرعى',
        AddCategory:'اضافه قسم',
        updateStore:'تعديل المحل',
        storeProfile:'بيانات المحل',
        chooseArea:'اختر الحى',
        secondPackage:'الباقه الثانيه',
        firstPackage:'الباقه الاولى',
        package:'الباقه ',
        discount:'الخصم',
        chooseSubCategory:'اختر القسم الفرعى',
        salesCode:'كود البيع',
        workTime:'مواعيد العمل',
        storeAbout:'معلومات عن المحل',
        subCategory:'قسم فرعى',
        email:'الايميل',
        storeName:'اسم المحل',
        StoresTable:'جدول المحلات',

        monthNum:'ترتيب القسط',
        paid:'المدفوع',
        onlyLastMonth:'اخر شهر فقط',
        chooseSalesMan:'اختر المندوب',
        chooseReceiptType:'اختر شكل الايصال',
        addCost:'اضافه مبلغ',
        cost:'المبلغ',
        give:'تسليم',
        receive:'استلام',
        theNaming:'المسمى',
        addTransaction:'اضافه معامله ماليه',
        addSupplier:'اضافه مورد',
        from:'مـن',
        to:'الـى',
        date:'التاريخ',
        money:'المبلغ',
        recieveReceivables:'المبالغ المستلمه',
        Dues:'المستحقات',
        pendingPremiums:'التحصيلات المتبقيه',
        finishedPremiums:'التحصيلات المكتمله',
        additionCollect:'التحصيل الاضافى',
        allPremiums:'التحصيل الكلى',
        daily:'اجمالى اليوم',
        receivedProducts:'المنتجات المستلمه',
        changeSalesMan:'تغيير المندوب',
        receiveMoney:'استلام مبلغ',
        TransferCollections:'نقل التحصيلات',
        Receivables:'المستحقات',
        collectedClients:'عملاء التحصيل',
        lastSeen:'اخر تواجد',
        addSalesMan:'اضافه مندوب',
        theAdd:'الاعلان',
        title:'العنوان',
        viewsCount:'عدد المشاهدات',
        ordersCount:'عدد المبيعات',
        statistic:'احصائيات',
        overView:'نظره عامه',
        dalyViews:'المشاهدات اليوميه',
        allViews:'المشاهدات الكليه',
        showAll:'عرض الكل',
        ads:'الاعلانات العقاريه',
        topProducts:'المنتجات الاكثر مبيعا',
        topSalesMan:'المندوب الاكثر مبيعات',
        topCompound:' التجمعات الاكثر مبيعا',
        topProduct:'المنتج الاكثر مبيعا',
        newUsers:'المستخدمين الجدد',
        allUsersCount:'عدد المستخدمين الكلى',
        compoundsInApp:'عدد التجمعات فالتطبيق',
        TotalSalesOfAllCompounds:'اجمالى المبيعات لجميع التجمعات',
        allSoldProducts:'عدد المنتجات المباعه',
        adsViewsCount:'اجمالى مشاهدات الاعلانات',
        product:'المنتج',
        chooseProduct:'اختر المنتج',
        count:'الكميه',
        cash:'كاش',
        payPremuims:'الاقساط المدفوعه',
        premuimCost:'قيمه القسط',
        premuimsCount:'عددد الاقساط',
        delayPremuims:'الاقساط المرحله',
        remainPremuims:'الاقساط المتبقيه',
        payCost:'المبلغ المدفوع',
        firstPay:'المقدم',
        remainCost:'المبلغ المتبقى',
        buySalesMan:'مندوب البيع',
        collectSalesMan:'مندوب التحصيل',
        DliveryNewProduct:'تسليم سلعه جديده',
        recievedProducts:'المنتجات المستلمه',
        showLocation:'اظهار الموقع',
        sendMessage:'رساله بالتطبيق',
        printReceitp:'طباعه ايصال',
        sendNotif:'ارسال اشعار',
        delayPremuim:'ترحيل قسط',
        payCash:'دفع كاش',
        collectPremuims:'تحصيل اقساط',
        enterPassword:'ادخل الرقم السرى',
        guarantor:'الضامن',
        client:'العميل',
        receipts:'ايصالات الامانه',
        cardImg:'صوره البطاقه',
        chooseCity:'اختر المحافظه',
        city:'المحافظه',
        note:'الملاحظات',
        anotherPhone:'رقم موبايل اخر',
        EnterPhone:'ادخل رقم الموبايل',
        EnterAnotherPhone:'ادخل رقم موبايل اخر',
        enterNote:'ادخل الملاحظات',
        enterAddress:'ادخل العنوان',
        enterCardNum:'ادخل رقم البطاقه',
        cardNum:'رقم البطاقه',
        profileImg:'صوره البروفايل',
        addClient:'اضافه عميل',
        enterPhone:'ادخل رقم الهاتف (اختيارى)  ',
        enterLink:' ادخل الرابط (اختيارى) ',
        link:'الرابط',
        visable:'عرض',
        chooseDate:'اختر التاريخ',
        products:'المنتجات',
        categories:'الاقسام',
        enterName:'ادخل الاسم',
        enterDuration:'ادخل المده',
        chooseCategory:' اختر القسم',
        chooseCompounds:'(اختيارى) اختر المجمع السكنى',
        chooseProducts:'(اختيارى) اختر المنتجات',
        optional:'اختيارى',
        startDate:'تاريخ البدايه',
        compounds:'مجمعات سكنية',
        imgOrVideo:'صوره/فيديو',
        show:'عرض',
        notShow:'غير معروض',
        day:'يوم ',
        addAds:'اضافه اعلان عقارى',
        theMonths:'الشهور',
        Properties:' العقارات',
        installmentPackages:'باقات التقسيط',
        firstPaid:'المقدم',
        months:'الشهور',
        installment:'قسط',
        theInstallment:'القسط',
        pound:'جنيه',
        available:'متاح',
        onlyInstallment:'تقسيط فقط',
        ratio:'نسبه',
        property:'عقار',
        description:'التفاصيل',
        properties:'',
        cashPrice:'سعر الكاش',
        imgsGroup:'مجموعه صور',
        writeArabicName:'ادخل الاسم بالعربيه',
        writeEnglishName:'ادخل الاسم بالانجليزيه',
        quantity:'الكميه',
        EnterQuantity:'ادخل الكميه',
        purchasePrice:'سعر الشراء',
        EnterPrice:'ادخل المبلغ',
        commission:'مكافئه البيع',
        mainImg:'الصوره الرئيسيه',
        arabicProductName:'اسم المنتج بالعربيه',
        englishProductName:'اسم المنتج بالانجليزيه', 
        print:'طباعه',
        all:'الكل',
        idFilter:' ( فلتر ( الكود ',
        nameFilter:'( فلتر ( الاسم',
        cardFilter:'( فلتر ( البطاقه',
        salesMan:'المندوب',
        salesManFilter:'فلتر (المندوب )',
        addProduct:'اضافه منتج',
        edit:'تعديل',
        add:'اضافه',
        dropHere:'قم بالسحب واسقط هنا',
        arabicCategoryName:'اسم القسم باللغه العربيه',
        englishCategoryName:'اسم القسم باللغه الانجليزيه',
        categoryImg:'صوره القسم',
        chooseFile:'اختر الملف',
        completeData:'املأ البيانات المطلوبه',
        noData:'لا يوجد بيانات',
        areYouSure:"هل انت متأكد؟",
        ok:'موافق',
        cancel:'غير موافق',
        addCategory:'اضافه قسم',
        enterCorrectPhone:'رقم هاتف غير صحيح',
        enterCorrectEmail:'بريد الكترونى غير صحيح',
        correctCode:'رمز خاطئ',
        passwordMustEqualConfirmPassword:'يجب ان يكون الرقم السرى يشابه الرقم المؤكد',
        done:'تمت العمليه بنجاح',
        newPassword:'الباسورد الجديد',
        newTime:'مره اخرى',
        code:'الرمز',
        enter:'ادخال',
        sendCode:'ارسال الرمز',
        forgetPassword:'نسيت كلمه السر؟',
        username:"اسم المستخدم",
        areaInfo:'معلومات الحى',
        updateArea:'تعديل الحى',
        updateCity:'تعديل المدينة',
        cityInfo:'معلومات المدينة',
        citiesTable:'المدن',
        delivaryCost:'سعر التوصيل',
        cities:'المدن',
        categoryInfo:'معلومات القسم',
        updateCategory:'تعديل القسم',
        categoriesTable:'الاقسام',
        arabicName:'الاسم بالعربية',
        englishName:'الاسم بالانجليزيه',
        normal:'طبيعى',
        disActive:'ايقاف التنشيط',
        removeOffer:'حذف العرض',
        userProfile:'بيانات المستخدم',
        sallCount:'الخصم',
        offerPrice:'السعر بعد الخصم',
        offerRatio:'نسبة الخصم',
        top:'توب',
        category:'القسم',
        name:'الاسم',
        compound:'مجمع سكنى',
        price:'السعر',
        hasOffer:'لدية عرض ',
        productsTable:'المنتجات',
        remove:'حذف',
        activeButton:'تنشيط',
        disActiveButton:'ايقاف التنشيط',
        blockButton:'الحظر',
        unblockButton:'فك الحظر',
        area:'الحى',
        updateProfile:'تعديل الملف الشخصى',
        address:'العنوان',
        signUpFrom:'التسجيل من خلال',
        active:'نشط',
        type:'النوع',
        thisUserIsnotAdmin:'هذا المستخدم ليس مسئولا',
        userDataIncorrect:'بيانات المستخدم غير صحيحة',
        firstName:'الاسم الاول',
        lastName:'باقى الاسم',
        phone:'رقم الموبايل',
        block:'الحظر',
        action:'الاجرائات',
        yes:'نعم',
        no:'لا',
        areYouSureDelete:'هل انت متاكد من الحذف ؟',
        addNewRecored:'اضافة عنصر جديد',
        first:'البداية',
        last:'الاخير',
        next:'التالى',
        prev:'السابق',
        login:"الدخول",
        signin:'تسجيل الدخول',
        password:'كلمة المرور',
    },
       
});
